import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["toggle"];

  connect() {
    this.setValue(true);
  }

  toggle() {
    const $toggle = this.toggleTarget;
    this.setValue($toggle.checked);
  }

  setValue(collapsed) {
    const $toggle = this.toggleTarget;
    $toggle.checked = collapsed;

    if (collapsed) {
      this.element.classList.add("is-collapsed");
    }
    else {
      this.element.classList.remove("is-collapsed");
    }
  }
}
