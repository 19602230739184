import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["checkbox"]
	connect() {

	}

  change(event) {
    this.checkboxTargets.filter(checkbox => checkbox != event.target).forEach(checkbox => {checkbox.checked = false})
  }

}
