import { Controller } from "stimulus"

export default class extends Controller {
    static targets = [ 'latitude', 'longitude']

    connect() {
        if (navigator.geolocation) navigator.geolocation.getCurrentPosition(this.setPosition.bind(this),
                                                                            null,
                                                                            {enableHighAccuracy: true})
    }

    setPosition(position) {
        this.latitude = position.coords.latitude
        this.longitude = position.coords.longitude
    }

    set latitude(latitude) {
        this.latitudeTarget.value = latitude
    }

    set longitude(longitude) {
        this.longitudeTarget.value = longitude
    }
}
