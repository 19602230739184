import { Controller } from "stimulus"

export default class extends Controller {
    static targets = []

	click(event) {
      event.preventDefault()
      window.print()
	}
}
