import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'defaultSelector', 'location']
  static classes = [ 'supported', 'ready']
  connect() {
    this.element.classList.add(this.supportedClass)
    if(this.hasDefaultSelectorTarget) this.defaultSelectorTarget.remove()
  }

  changeSubdivision() {
    if(this.hasLocationTarget) this.locationTarget.remove()
    this.element.submit()
  }

  changeLocation(event){
    if(event.target.value != '') {
      this.element.classList.add(this.readyClass)
    }
    else {
      this.element.classList.remove(this.readyClass)
    }
  }
}
