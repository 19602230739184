import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["slave", "master"]
  static values = {ignorePage: Boolean}
  connect() {
    this.refresh()
  }

  toggleMaster(){
    this.slaveTargets.forEach(s => s.checked = this.masterTarget.checked)
  }

  toggleSlave() {
    this.refresh()
  }

  refresh() {
    if(this.ignorePageValue){
      this.masterTarget.checked = false
    }
    else {
      this.masterTarget.checked = this.slaveTargets.every(slave => slave.checked)
    }
  }
}
