import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    'time',
    'overtime',
    'timeTotal',
    'overtimeTotal',
    'breakfastTotal',
    'lunchTotal',
    'dinnerTotal',
    'fullTotal',
    'breakfast',
    'lunch',
    'dinner',
    'full'
  ]

	connect() {
    this.change()
  }

  change(){
    this.timeTotalTarget.innerText = this.timeTotal
    this.overtimeTotalTarget.innerText = this.overtimeTotal
    let fullCount = this.calculateMealCount(this.fullTargets)
    let breakfastCount = this.calculateMealCount(this.breakfastTargets) - fullCount
    let lunchCount = this.calculateMealCount(this.lunchTargets) - fullCount
    let dinnerCount = this.calculateMealCount(this.dinnerTargets) - fullCount
    this.breakfastTotalTarget.innerText = breakfastCount
    this.lunchTotalTarget.innerText = lunchCount
    this.dinnerTotalTarget.innerText = dinnerCount
    this.fullTotalTarget.innerText = fullCount
  }


  get timeTotal() {
    return this.calculateTotal(this.timeTargets)
  }

  get overtimeTotal() {
    return this.calculateTotal(this.overtimeTargets)
  }

  calculateMealCount(targets) {
    return targets.filter(target => target.checked).length
  }

  calculateTotal(targets) {
    return targets.map(target => (parseFloat(target.value) || 0)).reduce((a,b) => a + b, 0)
  }
}
