import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['skipRadio']
  static classes = [ 'supported', 'folded']
  static values = {folded: Boolean}
	connect() {
    this.element.classList.add(this.supportedClass)
    if(this.allTestSkipped) {
      this.fold()
    }
	}

  get allTestSkipped() {
    return this.skipRadioTargets.every(radio => radio.checked)
  }

  toggle(){
    if(this.folded){
      this.unfold()
    }
    else {
      this.fold()
    }
  }

  get folded() {
    return this.foldedValue
  }

  set folded(fold){
    this.foldedValue = !!fold
  }

  unfold(){
    this.folded = false
    this.element.classList.remove(this.foldedClass)
  }

  fold() {
    this.folded = true
    this.element.classList.add(this.foldedClass)
  }

  skipBlock(event) {
    event.preventDefault()
    this.setAllSkip(true)
    this.fold()
  }

  setAllSkip(checked) {
    this.skipRadioTargets.forEach((radio) => {
      radio.checked = checked
    })
  }
}
