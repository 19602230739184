import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    'time',
    'overtime',
    'breakfast',
    'lunch',
    'dinner',
    'full'
  ]

	connect() {
  }


  reset() {
    this.timeTarget.value = null
    this.overtimeTarget.value = null
    this.breakfastTarget.checked = false
    this.lunchTarget.checked = false
    this.dinnerTarget.checked = false
    this.fullTarget.checked = false
  }
}
